import { useEffect, useMemo, useState } from 'react';

import {
  Box,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import { ROUTES, reverseRoute } from 'src/config/routes/routes';
import { formatSecondsToHrMinSec } from 'src/helpers/format';
import { decodeToken } from 'src/helpers/jwt';
import { useAppSelector } from 'src/redux/hooks';
import { FlowReturn } from 'src/types/utils';

import AlertDialog from '../AlertDialog/AlertDialog';

import './SessionTimer.scss';

/**
 * The SessionTimer component renders when tokens are set in the store and it has two purposes:
 * 1. to set a time-based loop for the automatic refresh of the tokens once the time spent
 *    corresponds to the token expiration time
 * 2. to set a timer for the idle time of the user, prompting them when they have been idle
 *    for too long. In the prompt dialog, if the user informs their presence, the token is refreshed
 *    and if the timer is exceeded, the user is logged out
 * 
 * Both timers consider the token expiration time:
 * - The idle timer prompts the user 2 minutes before the token expiration time is reached and
 * - The refresh token timer refreshes the token 10s before the token expiration time is reached.
 * 
 * The token expiration time is retrieved from the `expires_in` field in the token API response.
 * The `exp` field in the decoded token is considered in the refresh token timer calculation.
 */
const SessionTimer = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const tokens = useAppSelector((state) => state.auth.tokens);

  type Timeout = FlowReturn<typeof setTimeout>;
  const [refreshTokenTimeout, setRefreshTokenTimeout] = useState<Timeout | undefined>(undefined);
  const [remainingSeconds, setRemainingSeconds] = useState<number>(-1);
  const [idleDialogOpen, setIdleDialogOpen] = useState<boolean>(false);

  // Token expiration time in local storage for testing purposes

  const clearRefreshTokenTimeout = (timeout?: Timeout) => {
    clearTimeout(timeout || refreshTokenTimeout);
    setRefreshTokenTimeout(undefined);
  };

  const logout = () => navigate(reverseRoute(ROUTES.logout));

  const expirationInSeconds = useMemo(() => {
    if (!tokens || !tokens.expiresIn) return -1;

    // Current timestamp in seconds
    const currentTimestamp = Math.floor(Date.now() / 1000);

    return tokens.expiresIn - currentTimestamp;
  }, [tokens]);

  useEffect(() => {
    if (refreshTokenTimeout || !tokens?.accessToken) return;

    // refresh token timeout
    // refreshes tokens when token is close to expiring
    const timeout = setTimeout(() => {
      if (idleDialogOpen) return;

      // refreshTokens();
      clearRefreshTokenTimeout(timeout);
    }, expirationInSeconds * 1000 - 100); // 100 milliseconds security delay

    setRefreshTokenTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokens]);

  const { reset, getRemainingTime } = useIdleTimer({
    timeout: expirationInSeconds * 1000,
    promptBeforeIdle: 120000, // prompt 120 seconds before timeout
    startOnMount: true,
    crossTab: true,
    onPrompt: () => {
      setRemainingSeconds(Math.round(getRemainingTime() / 1000));
      setIdleDialogOpen(true);

      // countdown timer in dialog
      // sets up loop that runs every second until timer ends
      const interval = setInterval(() => {
        const remainingTime = getRemainingTime();

        if (remainingTime > 0) {
          setRemainingSeconds(Math.round(remainingTime / 1000));
        } else {
          setIdleDialogOpen(false);

          clearInterval(interval);
          clearRefreshTokenTimeout();

          logout();
        }
      }, 1000);
    },
  });

  const handleIamHere = () => {
    setIdleDialogOpen(false);

    clearRefreshTokenTimeout();
    // refreshTokens(); // TODO: prevent page refresh

    reset();
  };

  const handleSignOut = () => {
    setIdleDialogOpen(false);

    clearRefreshTokenTimeout();

    logout();
  };

  return (
    <Box data-testid="SessionTimer">
      {idleDialogOpen ? (
        <AlertDialog
          title={t('dialogIdle.title')}
          description={t('dialogIdle.description', { timeForIdleSession: 2 })}
          cancelButtonText={t('dialogIdle.signOut')}
          onCancelPress={handleSignOut}
          confirmButtonText={t('dialogIdle.imHere')}
          onConfirmPress={handleIamHere}
        >
          <Typography className="SessionTimer-timer">
            <b>
              {formatSecondsToHrMinSec('en', remainingSeconds)}
            </b>

            {` ${t('dialogIdle.timerRemaining')}`}
          </Typography>
        </AlertDialog>
      ) : null}
    </Box>
  );
};

export default SessionTimer;
