// Constants for groups

// size to do the fetch of the groups
export const FETCH_COHORT_SIZE_FOR_HOME = 2;
export const FETCH_COHORT_SIZE_FOR_COHORTS = 20;

// Enum for group types, today we only have cohort
export enum GroupType {
  COHORT = 'cohort',
}
