import { GROUPS_PATH_PATTERNS, generateApiUrl } from 'src/constants/api';
import { getValidationErrors } from 'src/types/validators/ajv';
import { GroupMemberGetResponse, validateGroupMemberGetResponse } from 'src/types/validators/GroupMemberGetResponse';
import { GroupMemberPostResponse, validateGroupMemberPostResponse } from 'src/types/validators/GroupMemberPostResponse';
import { GroupMemberRequest } from 'src/types/validators/GroupMemberRequest';
import { validateResponseErrorMessage } from 'src/types/validators/ResponseErrorMessage';
import { validateResponseErrorsList } from 'src/types/validators/ResponseErrorsList';

import { GroupMemberListQueryParams } from './types';

// Get groups from api
export async function getGroupMemberships(
  accessToken: string,
  groupId: string,
  queryParams: GroupMemberListQueryParams,
): Promise<GroupMemberGetResponse | undefined> {
  const route = generateApiUrl(GROUPS_PATH_PATTERNS.MEMBERSHIPS, { groupId }, queryParams);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method: 'GET',
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (process.env.NODE_ENV !== 'development') {
    if (response.status !== 200) {
      console.error(`Group members get request failed with status ${response.status}`, json);
    }

    return json;
  }

  if (response.status !== 200) {
    if (validateResponseErrorsList(json)) {
      console.error(`Group members get request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Group members get request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Group members get request failed with status ${response.status}`, json);
    }

    return undefined;
  }

  if (validateGroupMemberGetResponse(json)) {
    return json;
  }

  console.error('Groups get response structure is invalid. Probably API has changed', getValidationErrors(), json);

  return undefined;
}

// Post members to a group api
export async function postMembersToGroup(
  accessToken: string,
  groupId: string,
  groupMembers: GroupMemberRequest,
): Promise<GroupMemberPostResponse | undefined> {
  const route = generateApiUrl(GROUPS_PATH_PATTERNS.MEMBERSHIPS, { groupId });

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const options = {
    method: 'POST',
    body: JSON.stringify(groupMembers),
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (process.env.NODE_ENV !== 'development') {
    if (response.status !== 200) {
      console.error(`Group members post request failed with status ${response.status}`, json);
    }

    return json;
  }

  if (response.status !== 200) {
    if (validateResponseErrorsList(json)) {
      console.error(`Group members post request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Group members post request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Group members post request failed with status ${response.status}`, json);
    }

    return undefined;
  }

  if (validateGroupMemberPostResponse(json)) {
    return json;
  }

  console.error('Group member post response structure is invalid. Probably API has changed', getValidationErrors(), json);

  return undefined;
}

// Post members to a group api
export async function deleteMemberFromGroup(
  accessToken: string,
  groupId: string,
  userId: string,
): Promise<boolean> {
  const route = generateApiUrl(GROUPS_PATH_PATTERNS.MEMBERSHIP, { groupId, userId });

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method: 'DELETE',
    headers,
  };

  const response = await fetch(route, options);

  if (response.status !== 204) {
    const json = response.json().catch(console.error);
    if (validateResponseErrorsList(json)) {
      console.error(`Group delete request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Group delete request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Group delete request failed with status ${response.status}`, json);
    }

    return false;
  }

  return true;
}
