/* eslint-disable max-len */
/**
 * This file was generated by compileSchemas.ts script  
 * DO NOT modify it directly  
 * Instead make changes inside ../schemas/GroupMemberGetResponse.json and run  
 * npm run schema
 */

import ajv from './ajv';
import schema from '../schemas/GroupMemberGetResponse.json';

/**
 * list of users of a group saved in the database.
 */
export interface GroupMemberGetResponse {
  /**
   * A list of users
   */
  items: {
    /**
     * The unique identifier of the user cross-PL.
     */
    userId: string;
    /**
     * The first name of the user.
     */
    firstName: string;
    /**
     * The last name of the user.
     */
    lastName: string;
    [k: string]: unknown;
  }[];
  count: number;
  [k: string]: unknown;
}

export const validateGroupMemberGetResponse = (data: any): data is GroupMemberGetResponse => ajv.validate(schema, data);
