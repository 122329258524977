import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ObjectOfStrings } from 'src/constants/api';
import {
  deleteEvent as deleteEventApi, getEvent, getEvents, patchEvent, postEvent,
} from 'src/services/requests/event';
import { ThunkCaseHandlers } from 'src/types/Redux';
import { Status } from 'src/types/Status';
import { EventListResponse } from 'src/types/validators/EventListResponse';
import { EventRequest } from 'src/types/validators/EventRequest';
import { EventResponse } from 'src/types/validators/EventResponse';

import { EventState } from './types';
import type { RootState } from '../store';

// Fetch events
interface FetchEventsParams {
  dateQuery?: string;
  size?: string;
}

export const fetchEvents = createAsyncThunk(
  'events/fetchEvents',
  async ({
    dateQuery,
    size = '20',
  }: FetchEventsParams, { getState, rejectWithValue }) => {
    const { auth, user } = getState() as RootState;
    if (!auth.tokens || !auth.tokens.accessToken || !user.user?.id) {
      return rejectWithValue('fetchEvents: user is not logged in');
    }

    const eventQueryParams: ObjectOfStrings = { organizerId: user.user.id, size };

    if (dateQuery) {
      eventQueryParams.startAtGreaterThanEqual = `${dateQuery}T00:00:00`;
      eventQueryParams.endAtLowerThanEqual = `${dateQuery}T23:59:59`;
    }

    try {
      const response = await getEvents(auth.tokens.accessToken, eventQueryParams);
      if (!response) {
        return rejectWithValue('Failed to fetch events');
      }

      return response;
    } catch (err: any) {
      console.error(err);
      return rejectWithValue(err.message);
    }
  },
);

export const fetchEventsCaseHandlers: ThunkCaseHandlers<EventState> = {
  handlePending: (state) => {
    state.statusList = Status.LOADING;
  },
  handleFulfilled: (state, { payload }: PayloadAction<EventListResponse>) => {
    state.events = payload.items;
    state.statusList = Status.SUCCEEDED;
  },
  handleRejected: (state, { payload }) => {
    console.error(payload);
    state.statusList = Status.FAILED;
  },
};

// Fetch event
export const fetchEvent = createAsyncThunk(
  'events/fetchEvent',
  async (eventId: string, { getState, rejectWithValue }) => {
    const { auth } = getState() as RootState;
    if (!auth.tokens || !auth.tokens.accessToken) {
      return rejectWithValue('fetchEvent: user is not logged in');
    }

    try {
      const response = await getEvent(auth.tokens.accessToken, eventId);
      if (!response) {
        return rejectWithValue('Failed to fetch event');
      }

      return response;
    } catch (err: any) {
      console.error(err);
      return rejectWithValue(err.message);
    }
  },
);

export const fetchEventCaseHandlers: ThunkCaseHandlers<EventState> = {
  handlePending: (state) => {
    state.statusFetch = Status.LOADING;
  },
  handleFulfilled: (state, { payload }: PayloadAction<EventResponse>) => {
    state.event = payload;
    state.statusFetch = Status.SUCCEEDED;
  },
  handleRejected: (state, { payload }) => {
    console.error(payload);
    state.statusFetch = Status.FAILED;
  },
};

// Create event
export const createEvent = createAsyncThunk(
  'events/createEvent',
  async (eventData: EventRequest, { getState, rejectWithValue }) => {
    const { auth, user } = getState() as RootState;
    if (!auth.tokens || !auth.tokens.accessToken || !user.user?.id) {
      return rejectWithValue('createEvent: user is not logged in');
    }

    try {
      const response = await postEvent(auth.tokens.accessToken, { ...eventData, organizerId: user.user.id });
      if (!response) {
        return rejectWithValue('Failed to create event');
      }

      return response;
    } catch (err: any) {
      console.error(err);
      return rejectWithValue(err.message);
    }
  },
);

export const createEventCaseHandlers: ThunkCaseHandlers<EventState> = {
  handlePending: (state) => {
    state.statusCreate = Status.LOADING;
  },
  handleFulfilled: (state, { payload }: PayloadAction<EventResponse>) => {
    state.event = payload;
    state.statusCreate = Status.SUCCEEDED;
  },
  handleRejected: (state, { payload }) => {
    console.error(payload);
    state.statusCreate = Status.FAILED;
  },
};

// Update event
export const updateEvent = createAsyncThunk(
  'events/updateEvent',
  async (eventData: EventRequest, { getState, rejectWithValue }) => {
    const { auth } = getState() as RootState;
    if (!auth.tokens || !auth.tokens.accessToken) {
      return rejectWithValue('updateEvent: user is not logged in');
    }

    try {
      const response = await patchEvent(auth.tokens.accessToken, eventData);
      if (!response) {
        return rejectWithValue('Failed to update event');
      }

      return response;
    } catch (err: any) {
      console.error(err);
      return rejectWithValue(err.message);
    }
  },
);

export const updateEventCaseHandlers: ThunkCaseHandlers<EventState> = {
  handlePending: (state) => {
    state.statusUpdate = Status.LOADING;
  },
  handleFulfilled: (state, { payload }: PayloadAction<EventResponse>) => {
    state.event = payload;
    state.statusUpdate = Status.SUCCEEDED;
  },
  handleRejected: (state, { payload }) => {
    console.error(payload);
    state.statusUpdate = Status.FAILED;
  },
};

// Delete event
export const deleteEvent = createAsyncThunk(
  'events/deleteEvent',
  async (eventId: string, { getState, rejectWithValue }) => {
    const { auth } = getState() as RootState;
    if (!auth.tokens || !auth.tokens.accessToken) {
      return rejectWithValue('deleteEvent: user is not logged in');
    }

    try {
      const response = await deleteEventApi(auth.tokens.accessToken, eventId);
      if (!response) {
        return rejectWithValue('Failed to delete event');
      }

      return response;
    } catch (err: any) {
      console.error(err);
      return rejectWithValue(err.message);
    }
  },
);

export const deleteEventCaseHandlers: ThunkCaseHandlers<EventState> = {
  handlePending: (state) => {
    state.statusFetch = Status.LOADING;
  },
  handleFulfilled: (state) => {
    state.statusFetch = Status.SUCCEEDED;
  },
  handleRejected: (state, { payload }) => {
    console.error(payload);
    state.statusFetch = Status.FAILED;
  },
};
