import { REPORTS_PATH_PATTERNS, generateApiUrl } from 'src/constants/api';
import { validateResponseErrorMessage } from 'src/types/validators/ResponseErrorMessage';
import { validateResponseErrorsList } from 'src/types/validators/ResponseErrorsList';

export const getPowerBiAccessToReport = async ({
  groupId, reportId, reportSectionId, accessToken, userId,
}: {
  groupId: string,
  reportId: string,
  reportSectionId: string,
  accessToken: string,
  userId: string,
}) => {
  const route = generateApiUrl(REPORTS_PATH_PATTERNS.POWERBI_ACCESS);

  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'X-User-Id': userId,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ groupId, reportId, reportSectionId }),
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (!json) {
    console.error('getPowerBiAccessToReport: No response received.');
  }

  if (response.status !== 200) {
    if (validateResponseErrorsList(json)) {
      console.error(`getPowerBiAccessToReport:
        Request failed with status ${response.status}:\n${json.message || ''}\n${json.errors}`);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`getPowerBiAccessToReport:
        Request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`getPowerBiAccessToReport:
        Request failed with status ${response.status}\n${json}`);
    }

    return undefined;
  }

  return json;
};
