import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ObjectOfStrings } from 'src/constants/api';
import { GroupType } from 'src/constants/group';
import { Status } from 'src/types/Status';
import { FlowReturn } from 'src/types/utils';
import { GroupListResponse } from 'src/types/validators/GroupListResponse';
import { GroupRequest } from 'src/types/validators/GroupRequest';
import { GroupResponse } from 'src/types/validators/GroupResponse';

import {
  deleteGroup as deleteGroupFromApi, getGroup, getGroups, postGroup,
} from './api';
import { GroupCaseHandlers, GroupListCaseHandlers } from './types';
import type { RootState } from '../store';

interface FetchGroupsParams {
  size?: number,
  type?: GroupType,
  ownerId?: string | null,
}

export const fetchGroups = createAsyncThunk(
  'groups/fetchGroups',
  async ({
    ownerId = null,
    size,
    type = GroupType.COHORT,
  }: FetchGroupsParams, { getState, rejectWithValue }) => {
    const { auth } = getState() as RootState;

    if (!auth.tokens || !auth.tokens.accessToken) {
      return rejectWithValue('User is not logged in');
    }

    const groupQueryParams: ObjectOfStrings = { type };

    if (ownerId) {
      groupQueryParams.ownerId = ownerId;
    }

    if (size) {
      groupQueryParams.size = `${size}`;
    }

    try {
      const response = await getGroups(auth.tokens.accessToken, groupQueryParams);
      if (!response) {
        return rejectWithValue('Failed to fetch groups');
      }

      return response;
    } catch (err: any) {
      console.error(err);

      return rejectWithValue(err.message);
    }
  },
);

export const fetchGroupsCaseHandlers: GroupListCaseHandlers = {
  handlePending: (state) => {
    state.status = Status.LOADING;
  },
  handleFulfilled: (state, { payload }: PayloadAction<GroupListResponse>) => {
    if (payload.items?.length) {
      state.groups = payload.items;
    }
    state.status = Status.SUCCEEDED;
  },
  handleRejected: (state, { payload }) => {
    state.status = Status.FAILED;
    console.error(payload);
  },
};

export const fetchGroup = createAsyncThunk(
  'groups/fetchGroup',
  async (groupId: string, { getState, rejectWithValue }) => {
    const { auth } = getState() as RootState;
    if (!auth.tokens || !auth.tokens.accessToken) {
      return rejectWithValue('fetchGroup: user is not logged in');
    }

    try {
      const response = await getGroup(auth.tokens.accessToken, groupId);
      if (!response) {
        return rejectWithValue(`Failed to fetch group ${groupId}`);
      }

      return response;
    } catch (err: any) {
      console.error(err);

      return rejectWithValue(err.message);
    }
  },
);

export const fetchGroupCaseHandlers: GroupCaseHandlers = {
  handlePending: (state) => {
    state.status = Status.LOADING;
  },
  handleFulfilled: (state, { payload }: PayloadAction<GroupResponse>) => {
    state.group = payload;
    state.status = Status.SUCCEEDED;
  },
  handleRejected: (state, { payload }) => {
    state.status = Status.FAILED;
    console.error(payload);
  },
};

interface CreateGroupParams {
  accessToken: string,
  groupData: GroupRequest,
}

export const createGroup = createAsyncThunk(
  'groups/createGroup',
  async ({
    accessToken,
    groupData,
  }: CreateGroupParams, { rejectWithValue }) => {
    if (!accessToken) {
      return rejectWithValue('User is not logged in');
    }

    try {
      type GroupResponse = FlowReturn<typeof postGroup>;
      const response: GroupResponse = await postGroup(accessToken, groupData);
      if (!response) {
        return rejectWithValue('Group is not created');
      }

      return response;
    } catch (err: any) {
      console.error(err);

      return rejectWithValue(err.message);
    }
  },
);

export const createGroupCaseHandlers: GroupCaseHandlers = {
  handlePending: (state) => {
    state.status = Status.LOADING;
  },
  handleFulfilled: (state) => {
    state.status = Status.SUCCEEDED;
  },
  handleRejected: (state, { payload }) => {
    state.status = Status.FAILED;
    console.error(payload);
  },
};

export const deleteGroup = createAsyncThunk(
  'groups/deleteGroup',
  async (groupId: string, { getState, rejectWithValue }) => {
    const { auth } = getState() as RootState;
    if (!auth.tokens || !auth.tokens.accessToken) {
      return rejectWithValue('User is not logged in');
    }

    try {
      const response = await deleteGroupFromApi(auth.tokens.accessToken, groupId);
      if (!response) {
        return rejectWithValue(`Failed to delete group ${groupId}`);
      }

      return response;
    } catch (err: any) {
      console.error(err);

      return rejectWithValue(err.message);
    }
  },
);

export const deleteGroupCaseHandlers: GroupCaseHandlers = {
  handlePending: (state) => {
    state.status = Status.LOADING;
  },
  handleFulfilled: (state) => {
    state.status = Status.SUCCEEDED;
  },
  handleRejected: (state) => {
    state.status = Status.FAILED;
  },
};
