/* eslint-disable max-len */
/**
 * This file was generated by compileSchemas.ts script  
 * DO NOT modify it directly  
 * Instead make changes inside ../schemas/GroupResponse.json and run  
 * npm run schema
 */

import ajv from './ajv';
import schema from '../schemas/GroupResponse.json';

/**
 * A group of users
 */
export interface GroupResponse {
  /**
   * The unique identifier of the group.
   */
  id: string;
  /**
   * The title of the group.
   */
  title: string;
  /**
   * The description of the group.
   */
  description: string;
  /**
   * The template of channels of the group.
   */
  template: string;
  /**
   * The type of the group.
   */
  type: 'cohort';
  /**
   * The unique identifier of the owner of the group.
   */
  ownerId: string;
  /**
   * A list of memberships of the group.
   */
  memberships: {
    /**
     * The unique identifier of the user.
     */
    userId?: string;
    [k: string]: unknown;
  }[];
  /**
   * context of the cohort
   */
  context: {
    /**
     * Id of the teams channel
     */
    msteamsId: string;
    /**
     * Link of the teams channel
     */
    msteamsUrl: string | null;
    /**
     * Id of the program linked to the group
     */
    trainingProgramId?: string;
    [k: string]: unknown;
  };
  /**
   * The date and time when the group was created, represented in the ISO 8601 GMT format (datetime) according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   *
   */
  createdAt: string;
  /**
   * The date and time when the group was last updated, represented in the ISO 8601 GMT format (datetime) according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   *
   */
  updatedAt: string;
  [k: string]: unknown;
}

export const validateGroupResponse = (data: any): data is GroupResponse => ajv.validate(schema, data);
