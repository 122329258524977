import {
  GROUPS_PATH_PATTERNS, generateApiUrl, ObjectOfStrings,
} from 'src/constants/api';
import { getValidationErrors } from 'src/types/validators/ajv';
import { GroupListResponse, validateGroupListResponse } from 'src/types/validators/GroupListResponse';
import { GroupRequest } from 'src/types/validators/GroupRequest';
import { GroupResponse, validateGroupResponse } from 'src/types/validators/GroupResponse';
import { validateResponseErrorMessage } from 'src/types/validators/ResponseErrorMessage';
import { validateResponseErrorsList } from 'src/types/validators/ResponseErrorsList';

// Get groups from api
export async function getGroups(
  accessToken: string,
  queryParams: ObjectOfStrings,
): Promise<GroupListResponse | undefined> {
  const route = generateApiUrl(GROUPS_PATH_PATTERNS.ROOT, undefined, queryParams);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method: 'GET',
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (process.env.NODE_ENV !== 'development') {
    if (response.status !== 200) {
      console.error(`Groups get request failed with status ${response.status}`, json);
    }

    return json as GroupListResponse;
  }

  if (response.status !== 200) {
    if (validateResponseErrorsList(json)) {
      console.error(`Groups get request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Groups get request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Groups get request failed with status ${response.status}`, json);
    }

    return undefined;
  }

  if (validateGroupListResponse(json)) {
    return json;
  }

  console.error('Groups get response structure is invalid. Probably API has changed', getValidationErrors(), json);

  return undefined;
}

// Get groups from api
export async function getGroup(
  accessToken: string,
  groupId: string,
): Promise<GroupResponse | undefined> {
  const route = generateApiUrl(GROUPS_PATH_PATTERNS.GROUP, { groupId });

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method: 'GET',
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (process.env.NODE_ENV !== 'development') {
    if (response.status !== 200) {
      console.error(`Group get request failed with status ${response.status}`, json);
    }

    return json as GroupResponse;
  }

  if (response.status !== 200) {
    if (validateResponseErrorsList(json)) {
      console.error(`Group get request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Group get request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Group get request failed with status ${response.status}`, json);
    }

    return undefined;
  }

  if (validateGroupResponse(json)) {
    return json;
  }

  console.error('Group get response structure is invalid. Probably API has changed', getValidationErrors(), json);

  return undefined;
}

// Post a group to api
export async function postGroup(
  accessToken: string,
  groupData: GroupRequest,
): Promise<GroupResponse | undefined> {
  const route = generateApiUrl(GROUPS_PATH_PATTERNS.ROOT);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const options = {
    method: 'POST',
    body: JSON.stringify(groupData),
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (process.env.NODE_ENV !== 'development') {
    if (response.status !== 201) {
      console.error(`Group post request failed with status ${response.status}`, json);
    }

    return json as GroupResponse;
  }

  if (response.status !== 201) {
    if (validateResponseErrorsList(json)) {
      console.error(`Group post request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Group post request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Group post request failed with status ${response.status}`, json);
    }

    return undefined;
  }

  if (validateGroupResponse(json)) {
    return json;
  }

  console.error('Group post response structure is invalid. Probably API has changed', getValidationErrors(), json);

  return undefined;
}

// Delete groups from api
export async function deleteGroup(
  accessToken: string,
  groupId: string,
): Promise<boolean> {
  const route = generateApiUrl(GROUPS_PATH_PATTERNS.GROUP, { groupId });

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method: 'DELETE',
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (process.env.NODE_ENV !== 'development') {
    if (response.status !== 204) {
      console.error(`Group delete request failed with status ${response.status}`, json);
    }

    return true;
  }

  if (response.status !== 204) {
    if (validateResponseErrorsList(json)) {
      console.error(`Group delete request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Group delete request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Group delete request failed with status ${response.status}`, json);
    }

    return false;
  }

  return true;
}
