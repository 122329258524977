import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import authReducer from './auth/slice';
import enrollmentsReducer from './enrollments/slice';
import eventReducer from './event/slice';
import facilitatorProgramListReducer from './facilitatorProgramList/slice';
import { groupCreateSliceReducer, groupListSliceReducer, groupFetchSliceReducer } from './group/slice';
import { groupMemberListSliceReducer } from './groupMembers/slice';
import imageReducer from './image/slice';
import learningObjectsReducer from './learningObjects/slice';
import organizationsReducer from './organizations/slice';
import reportsReducer from './reports/slice';
import themeReducer from './theme/slice';
import trackingReducer from './tracking/slice';
import trainingProgramReducer from './trainingProgram/slice';
import trainingProgramListReducer from './trainingProgramList/slice';
import { userSearchReducer, userReducer } from './user/slice';

export const storeReducer = {
  auth: authReducer,
  user: userReducer,
  trainingProgram: trainingProgramReducer,
  trainingProgramList: trainingProgramListReducer,
  learningObjects: learningObjectsReducer,
  images: imageReducer,
  event: eventReducer,
  groupList: groupListSliceReducer,
  groupCreate: groupCreateSliceReducer,
  groupFetch: groupFetchSliceReducer,
  groupMemberList: groupMemberListSliceReducer,
  enrollments: enrollmentsReducer,
  reports: reportsReducer,
  theme: themeReducer,
  organizations: organizationsReducer,
  tracking: trackingReducer,
  userSearch: userSearchReducer,
  facilitatorProgramList: facilitatorProgramListReducer,
};

export const store = configureStore({
  reducer: storeReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
