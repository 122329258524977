/* eslint-disable key-spacing */
import config from 'src/services/config/config';
import ConfigClient from 'src/services/config/ConfigClient';

// API CONSTANTS
export const KEY_REFRESH_TOKEN = 'refresh_token';
export const KEY_LEARNING_OBJECT_ID = 'learning_object';

export const REFRESH_TOKENS = {
  CLIENT_ID_FRONTEND_CLIENT: 'frontend-client',
  GRANT_TYPE_REFRESH_TOKEN: 'refresh_token',
  KEY_CLIENT_ID: 'client_id',
  KEY_GRANT_TYPE: 'grant_type',
};

// new structure
ConfigClient.init(config);

export type ObjectOfStrings = {
  [key: string]: string
};

type ObjectOfStringsOrNumbers = {
  [key: string]: string | number
};

/**
 * Generates the API URL by replacing the params in the URL patters by the values in the params object parameter.
 * It also concatenates the query params according to the object in the queryParams object parameter.
 * The pathPattern parameter should be one of the path patterns in this file.
 */
export function generateApiUrl(
  pathPattern: string,
  params?: ObjectOfStrings,
  queryParams?: ObjectOfStringsOrNumbers,
): string {
  let path = pathPattern;

  if (params) {
    path = Object.keys(params).reduce(
      (acc, key) => acc.replace(`%${key}`, params[key]),
      pathPattern,
    );
  }

  if (queryParams) {
    path += Object.keys(queryParams).reduce(
      (acc, key, index) => `${acc}${index > 0 ? '&' : ''}${key}=${queryParams[key]}`,
      '?',
    );
  }

  return `${ConfigClient.get('API_BASE_URL')}/api/v4${path}`;
}

const ORGANIZATION_ROLES_API_PATH = '/organization-roles';
export const ORGANIZATION_ROLES_PATH_PATTERNS = {
  ROOT: `${ORGANIZATION_ROLES_API_PATH}`,
};

const OPENID_CONNECT_API_PATH = '/openid-connect';
export const OPENID_CONNECT_PATH_PATTERNS = {
  ROOT:   `${OPENID_CONNECT_API_PATH}`,
  TOKEN:  `${OPENID_CONNECT_API_PATH}/token`,
};

export const OPEN_CONNECT_API_PARAMS = {
  GRANT_TYPE_AUTHORIZATION_CODE:  'authorization_code',
  KEY_CODE:                       'code',
  KEY_GRANT_TYPE:                 'grant_type',
  KEY_REDIRECT_URI:               'redirect_uri',
  KEY_USER_MAPPING_ID:            'user_mapping_id',
};

const USER_API_PATH = '/users';
export const USER_PATH_PATTERNS = {
  ROOT:             `${USER_API_PATH}`,

  USER:             `${USER_API_PATH}/%userId`,
  USER_MAPPINGS:    `${USER_API_PATH}/%userId/user-mappings`,

  LOGIN:            `${USER_API_PATH}/login`,
  RECOVER_PASSWORD: `${USER_API_PATH}/recover-password`,
  CHANGE_PASSWORD:  `${USER_API_PATH}/change-password`,
  SEARCH:           `${USER_API_PATH}/search`,
};

const LO_API_PATH = '/learning-objects';
export const LO_PATH_PATTERNS = {
  ROOT:      `${LO_API_PATH}`,
  SINGLE_LO: `${LO_API_PATH}/%id`,
  SEARCH:    `${LO_API_PATH}/search`,
};

const TP_API_PATH = '/training-programs';
export const TP_PATH_PATTERNS = {
  ROOT:           `${TP_API_PATH}`,
  PROGRAM:        `${TP_API_PATH}/%programId`,
  PROGRAM_STEPS:  `${TP_API_PATH}/%programId/steps`,
  STEP:           `${TP_API_PATH}/%programId/steps/%stepId`,
  STEP_LOS:       `${TP_API_PATH}/%programId/steps/%stepId/learning-objects`,
  LO:             `${TP_API_PATH}/%programId/steps/%stepId/learning-objects/%learningObjectId`,
  LO_LAUNCH:      `${TP_API_PATH}/%programId/steps/%stepId/learning-objects/%learningObjectId/launch`,
};

const IMAGE_API_PATH = '/images';
export const IMAGE_PATH_PATTERNS = {
  ROOT:   `${IMAGE_API_PATH}`,
  IMAGE:  `${IMAGE_API_PATH}/%imageId`,
};

const EVENTS_API = '/events';
export const EVENTS_PATH_PATTERNS = {
  ROOT: `${EVENTS_API}`,
  EVENT: `${EVENTS_API}/%eventId`,
};

const REPORTS_API_PATH = '/reports';
export const REPORTS_PATH_PATTERNS = {
  ROOT:           `${REPORTS_API_PATH}`,
  POWERBI_ACCESS: `${REPORTS_API_PATH}/powerbi-access`,
};

// patterns for groups api
const GROUPS_API = '/groups';
export const GROUPS_PATH_PATTERNS = {
  ROOT: `${GROUPS_API}`,
  GROUP: `${GROUPS_API}/%groupId`,
  MEMBERSHIPS: `${GROUPS_API}/%groupId/memberships`,
  MEMBERSHIP: `${GROUPS_API}/%groupId/memberships/%userId`,
};

const ENROLLMENTS_API_PATH = '/enrollments';
export const ENROLLMENTS_PATH_PATTERNS = {
  ROOT:        `${ENROLLMENTS_API_PATH}/`,
  ENROLLMENT:  `${ENROLLMENTS_API_PATH}/%enrollmentId/`,
};

const CK2SGP_API_PATH = '/ck2sgp';
export const CK2SGP_PATH_PATTERNS = {
  ROOT:        `${CK2SGP_API_PATH}/`,
  BLENDEDX_URL:`${CK2SGP_API_PATH}/to-ckls-blendedx`,
};

const ORGANIZATIONS_API_PATH = '/organizations';
export const ORGANIZATION_PATH_PATTERNS = {
  ROOT:                 `${ORGANIZATIONS_API_PATH}/`,
  SINGLE_ORGANIZATION:  `${ORGANIZATIONS_API_PATH}/%organizationId/`,
};

// Tracking
const TRACKING_API_PATH = '/trackings';
export const TRACKING_PATH_PATTERNS = {
  SEARCH: `${TRACKING_API_PATH}/search/`,
};
