const config = {
  NODE_ENV: process.env.NODE_ENV,
  TEST_CONFIG: process.env.REACT_APP_TEST_CONFIG,
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL || 'mocked-api',
  // TODO: Should use the same BASE_URL when is available
  API_BASE_URL_FL: process.env.REACT_APP_API_BASE_URL_FL || 'http://localhost:5001',
  // For pendo config, we use directly env variables in index.html, but we still keep them here in config for future need
  PENDO_SOURCE_DOMAIN: process.env.REACT_APP_PENDO_SOURCE_DOMAIN,
  PENDO_API_KEY: process.env.REACT_APP_PENDO_API_KEY,
};

export type Config = typeof config;
export type ConfigKeys = keyof Config;

export default config;
