import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getTrainingProgramList } from 'src/services/requests/trainingProgramList';
import { ThunkCaseHandlers } from 'src/types/Redux';
import { TrainingProgramResponse } from 'src/types/requests/TrainingPrograms';
import { Status } from 'src/types/Status';
import { FlowReturn } from 'src/types/utils';

import { FacilitatorProgramListState } from './types';
import type { RootState } from '../store';

export const fetchFacilitatorProgramList = createAsyncThunk(
  'cohortProgramList/fetchCohortProgramList',
  async (organizationIds: number[], { getState, rejectWithValue }) => {
    const { auth } = getState() as RootState;
    if (!auth.tokens || !auth.tokens.accessToken) {
      return rejectWithValue('fetchFacilitatorProgramList: user is not logged in');
    }
    const organizationIdsStr = organizationIds.join(',');

    const queryParams = { organizationIds: organizationIdsStr };

    type Response = FlowReturn<typeof getTrainingProgramList>;
    const response: Response = await getTrainingProgramList(auth.tokens.accessToken, queryParams);

    if (!response) {
      return rejectWithValue('fetchFacilitatorProgramList: could not fetch program list');
    }

    const { items } = response;

    return { items };
  },
);

export const fetchFacilitatorProgramListCaseHandlers: ThunkCaseHandlers<FacilitatorProgramListState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
    state.trainingPrograms = [];
  },
  handleFulfilled: (state, { payload }: PayloadAction<{
    items: TrainingProgramResponse[]
  }>) => {
    state.trainingPrograms = payload.items;
    state.status = Status.SUCCEEDED;
  },
  handleRejected: (state, { payload }: PayloadAction<string>) => {
    state.status = Status.FAILED;
    console.error(payload);
  },
};
