import {
  generateApiUrl, ORGANIZATION_ROLES_PATH_PATTERNS, USER_PATH_PATTERNS,
} from 'src/constants/api';
import { SearchUserResponse, UserDataResponse, UserMappingsResponse } from 'src/types/requests/User';
import { getValidationErrors } from 'src/types/validators/ajv';
import { OrganizationRolesResponse } from 'src/types/validators/OrganizationRolesResponse';
import { validateResponseErrorMessage } from 'src/types/validators/ResponseErrorMessage';
import { validateResponseErrorsList } from 'src/types/validators/ResponseErrorsList';
import { UserSearchRequest } from 'src/types/validators/UserSearchRequest';
import { validateUserSearchResponse } from 'src/types/validators/UserSearchResponse';

export async function getUserInfo(userId: string, accessToken: string) {
  const route = generateApiUrl(USER_PATH_PATTERNS.USER, { userId });

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method: 'GET',
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);
  if (!json) {
    return undefined;
  }

  if (process.env.NODE_ENV !== 'development') {
    if (response.status !== 200) {
      console.error(`User request failed with status ${response.status}`, json);
    }

    return json as UserDataResponse;
  }

  if (response.status === 403) {
    console.error("getUserInfo 403: Probably the user you're trying to request does not belong in your organization.");
    return undefined;
  }

  if (response.status !== 200) {
    if (validateResponseErrorsList(json)) {
      console.error(`User request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`User request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`User request failed with status ${response.status}`, json);
    }
    return undefined;
  }

  return json as UserDataResponse;
}

/**
 * Call api to get organization roles for a given user
 */
export async function getOrganizationRoles(
  userId: string,
  accessToken: string,
): Promise<OrganizationRolesResponse | undefined> {
  const route = generateApiUrl(ORGANIZATION_ROLES_PATH_PATTERNS.ROOT, undefined, { userId, organizationType: 'Talent' });

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method: 'GET',
    headers,
  };

  const response = await fetch(route, options);

  if (response.status !== 200) {
    console.error(`Organization roles get request failed with status ${response.status}`);

    return undefined;
  }

  const json = await response.json().catch(console.error);
  if (Object.keys(json).length === 0) {
    console.error('Organization roles get request failed with empty payload');

    return undefined;
  }

  return json;
}

export async function searchUser(username: string, accessToken: string) {
  const route = generateApiUrl(USER_PATH_PATTERNS.SEARCH);

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {
    username,
  };

  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  };

  const response = await fetch(route, options);
  const json: SearchUserResponse = await response.json().catch(console.error);

  if (!json.items || !json.items[0]) {
    return undefined;
  }

  return json.items[0];
}

export async function getUserMappings(userId: string, accessToken: string): Promise<UserMappingsResponse | undefined> {
  const route = generateApiUrl(USER_PATH_PATTERNS.USER_MAPPINGS, { userId });

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method: 'GET',
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);
  if (!json) {
    return undefined;
  }

  if (process.env.NODE_ENV !== 'development') {
    if (response.status !== 200) {
      console.error(`User request failed with status ${response.status}`, json);
    }

    return json as UserMappingsResponse;
  }

  if (response.status !== 200) {
    console.error(`Organization roles get request failed with status ${response.status}`);

    return undefined;
  }

  return json as UserMappingsResponse;
}

/**
 * Call api to search users
 */
export async function searchUsersFromApi(accessToken: string, searchOptions: UserSearchRequest, signal?: AbortSignal) {
  const route = generateApiUrl(USER_PATH_PATTERNS.SEARCH);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const options = {
    method: 'POST',
    body: JSON.stringify(searchOptions),
    headers,
    signal,
  };

  let response: Response;
  try {
    response = await fetch(route, options);
  } catch (e) {
    console.error('Unexpected search fetch error', e);
    return;
  }

  let json: any;
  try {
    json = await response.json();
  } catch (e) {
    console.error('Unable to serialize search result', e);
    return;
  }

  if (process.env.NODE_ENV === 'development' && !validateUserSearchResponse(json)) {
    console.warn(
      'Search result structure is invalid. Probably api has changed.',
      getValidationErrors(),
      json,
    );
  }

  return json;
}
