import { createSlice } from '@reduxjs/toolkit';
import { Status } from 'src/types/Status';

import {
  createEvent, createEventCaseHandlers,
  deleteEvent, deleteEventCaseHandlers,
  fetchEvent, fetchEventCaseHandlers,
  fetchEvents, fetchEventsCaseHandlers,
  updateEvent, updateEventCaseHandlers,
} from './thunks';
import { EventState } from './types';

const initialState: EventState = {
  events: [],
  statusCreate: Status.IDLE,
  statusDelete: Status.IDLE,
  statusFetch: Status.IDLE,
  statusList: Status.IDLE,
  statusUpdate: Status.IDLE,
};

const eventSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    clearEventList: (state) => {
      state.events = [];
      state.statusList = Status.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch events
      .addCase(fetchEvents.pending, fetchEventsCaseHandlers.handlePending)
      .addCase(fetchEvents.fulfilled, fetchEventsCaseHandlers.handleFulfilled)
      .addCase(fetchEvents.rejected, fetchEventsCaseHandlers.handleRejected)

      // fetch event
      .addCase(fetchEvent.pending, fetchEventCaseHandlers.handlePending)
      .addCase(fetchEvent.fulfilled, fetchEventCaseHandlers.handleFulfilled)
      .addCase(fetchEvent.rejected, fetchEventCaseHandlers.handleRejected)

      // create event
      .addCase(createEvent.pending, createEventCaseHandlers.handlePending)
      .addCase(createEvent.fulfilled, createEventCaseHandlers.handleFulfilled)
      .addCase(createEvent.rejected, createEventCaseHandlers.handleRejected)

      // update event
      .addCase(updateEvent.pending, updateEventCaseHandlers.handlePending)
      .addCase(updateEvent.fulfilled, updateEventCaseHandlers.handleFulfilled)
      .addCase(updateEvent.rejected, updateEventCaseHandlers.handleRejected)

      // delete event
      .addCase(deleteEvent.pending, deleteEventCaseHandlers.handlePending)
      .addCase(deleteEvent.fulfilled, deleteEventCaseHandlers.handleFulfilled)
      .addCase(deleteEvent.rejected, deleteEventCaseHandlers.handleRejected);
  },
});

export const { clearEventList } = eventSlice.actions;

export default eventSlice.reducer;
