/* eslint-disable max-len */
/**
 * This file was generated by compileSchemas.ts script  
 * DO NOT modify it directly  
 * Instead make changes inside ../schemas/TrainingProgramListResponse.json and run  
 * npm run schema
 */

import ajv from './ajv';
import schema from '../schemas/TrainingProgramListResponse.json';

/**
 * Object a list of training programs and number of TPs saved in the database.
 */
export interface TrainingProgramListResponse {
  /**
   * A list of training programs
   */
  items: {
    /**
     * The unique identifier of the training program.
     */
    id: string;
    /**
     * The title of the training program.
     */
    title: string;
    /**
     * The description of the training program.
     */
    description: string | null;
    /**
     * The estimated duration of the training program in seconds.
     */
    estimatedDuration: number | null;
    /**
     * The language tag of the training program, according to [BCP 47](https://www.techonthenet.com/js/language_tags.php). It is typically composed by two ISO 639-1 alphanumeric letters in lower-case (representing the language itself), a hyphen and two (or three) ISO 3166-1 alphanumeric letters in upper-case (representing the country variation).
     *
     */
    languageTag: string;
    /**
     * A common reference identifier to group multiple training programs with the same meaning.
     */
    referenceCode: string | null;
    /**
     * The path (relative to the provider) to the image thumbnail file.
     */
    thumbnailPath?: string | null;
    /**
     * The list of tags that categorize the training program.
     */
    tags?: string[];
    /**
     * The list of authors of the training program.
     */
    authors?: string[];
    /**
     * The list of organization IDs that have visibility on the training program.
     */
    organizationIds: string[];
    /**
     * The list of product IDs that have visibility on the training program.
     */
    productIds?: string[];
    /**
     * A boolean field indicating that the training program is discoverable without an assignment.
     */
    isDiscoverable?: boolean;
    /**
     * The list of steps that belongs to the training program.
     */
    steps: TrainingProgramStep[];
    /**
     * The date and time when the training program was created, represented in the ISO 8601 GMT format (datetime) according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
     *
     */
    createdAt: string;
    /**
     * The date and time when the training program was last updated, represented in the ISO 8601 GMT format (datetime) according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
     *
     */
    updatedAt: string;
    /**
     * The set of HAL references to navigate from the current object. For other relations and/or their definitions, please [check this documentation](https://www.iana.org/assignments/link-relations/link-relations.xhtml).
     *
     */
    _links?: {
      /**
       * The reference for a related endpoint (from a HATEOAS property).
       */
      self?: {
        /**
         * The absolute URL of the relation endpoint.
         */
        href?: string;
        [k: string]: unknown;
      };
      [k: string]: unknown;
    };
    [k: string]: unknown;
  }[];
  count: number;
  /**
   * The set of HAL references to navigate from the current object. For other relations and/or their definitions, please [check this documentation](https://www.iana.org/assignments/link-relations/link-relations.xhtml).
   *
   */
  _links?: {
    /**
     * The reference for a related endpoint (from a HATEOAS property).
     */
    self?: {
      /**
       * The absolute URL of the relation endpoint.
       */
      href?: string;
      [k: string]: unknown;
    };
    /**
     * The reference for next related endpoint (from a HATEOAS property).
     */
    next?: {
      /**
       * The absolute URL of the relation endpoint.
       */
      href?: string;
      [k: string]: unknown;
    };
    [k: string]: unknown;
  };
  [k: string]: unknown;
}
/**
 * Steps are containers in a training program and contain learning objects.
 */
export interface TrainingProgramStep {
  /**
   * The unique identifier of the step.
   */
  id?: string;
  /**
   * The title of the step.
   */
  title?: string;
  /**
   * The description of the step.
   */
  description?: string | null;
  /**
   * The estimated duration of the step in seconds. Computed from the learning objects estimated duration part of the step
   */
  estimatedDuration?: number | null;
  /**
   * The date and time when the step was created, represented in the ISO 8601 GMT format (datetime) according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   *
   */
  createdAt?: string;
  /**
   * The date and time when the step was last updated, represented in the ISO 8601 GMT format (datetime) according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   *
   */
  updatedAt?: string;
  /**
   * List of learning objects Ids part of this step
   */
  learningObjects?: string[];
  /**
   * The set of HAL references to navigate from the current object. For other relations and/or their definitions, please [check this documentation](https://www.iana.org/assignments/link-relations/link-relations.xhtml).
   *
   */
  _links?: {
    /**
     * The reference for a related endpoint (from a HATEOAS property).
     */
    self?: {
      /**
       * The absolute URL of the relation endpoint.
       */
      href?: string;
      [k: string]: unknown;
    };
    [k: string]: unknown;
  };
  [k: string]: unknown;
}

export const validateTrainingProgramListResponse = (data: any): data is TrainingProgramListResponse => ajv.validate(schema, data);
