/* eslint-disable max-len */
/**
 * This file was generated by compileSchemas.ts script  
 * DO NOT modify it directly  
 * Instead make changes inside ../schemas/EventResponse.json and run  
 * npm run schema
 */

import ajv from './ajv';
import schema from '../schemas/EventResponse.json';

/**
 * An event
 */
export interface EventResponse {
  /**
   * The unique identifier of the event
   */
  id: string;
  /**
   * Title of the event
   */
  title: string;
  /**
   * Type of the event
   */
  type: 'FACILITATED' | 'WEBINAR' | 'WEBCAST';
  /**
   * Description of objective of the event
   */
  objectives?: string | null;
  /**
   * The unique identifier of the cohort related to the event
   */
  cohortId?: string;
  /**
   * The unique identifier of the training program related to the event
   */
  trainingProgramId?: string;
  /**
   * Exact date and time scheduled for the event to start
   */
  startAt: string;
  /**
   * Exact date and time scheduled for the event to end
   */
  endAt: string;
  /**
   * The date and time when the event was created, represented in the ISO 8601 GMT format (datetime) according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   *
   */
  createdAt?: string;
  /**
   * The date and time when the event was last updated, represented in the ISO 8601 GMT format (datetime) according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   *
   */
  updatedAt?: string;
  /**
   * The ID of the user who is organizing the event.
   */
  organizerId: string;
  [k: string]: unknown;
}

export const validateEventResponse = (data: any): data is EventResponse => ajv.validate(schema, data);
