import { PayloadAction } from '@reduxjs/toolkit';
import { ParsedPlatformData, UserMapping } from 'src/types/requests/User';
import { Status } from 'src/types/Status';

export interface UserModel {
  id?: string,
  firstName?: string,
  lastName?: string,
  email?: string,
  avatarUrl?: string,
  roles: string[],
  activeRole: string,
  language?: string;
}

export interface SearchUserModel {
  id: string,
  username: string,
  firstName: string,
  lastName: string,
  password: string,
  status: string,
  language: string | null,
  createdAt: string,
  updatedAt: string,
  userIdentifier: string,
}

export interface UserState {
  status: Status
  user?: UserModel
  organizationIds: number[]
  userMappings?: UserMapping[],
  userMappingId?: number,
  platformData?: ParsedPlatformData;
}

export enum PlatformRoles {
  LEARNER = 'learner',
  ADMIN = 'admin',
}

export interface UserSearchState {
  status: Status
  userResults: SearchUserModel[],
}

export type SearchUsersCaseHandlers = {
  [key: string]: (
    state: UserSearchState,
    action: PayloadAction<any>
  ) => void
};
