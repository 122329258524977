/* eslint-disable max-len */
/**
 * This file was generated by compileSchemas.ts script  
 * DO NOT modify it directly  
 * Instead make changes inside ../schemas/GroupMemberPostResponse.json and run  
 * npm run schema
 */

import ajv from './ajv';
import schema from '../schemas/GroupMemberPostResponse.json';

/**
 * list of users of a group saved in the database.
 */
export interface GroupMemberPostResponse {
  /**
   * A list of users
   */
  items: {
    /**
     * The unique identifier of the user cross-PL.
     */
    userId: string;
    [k: string]: unknown;
  }[];
  count: number;
  [k: string]: unknown;
}

export const validateGroupMemberPostResponse = (data: any): data is GroupMemberPostResponse => ajv.validate(schema, data);
