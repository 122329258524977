import {
  generateApiUrl,
  TP_PATH_PATTERNS,
} from 'src/constants/api';
import { TrainingProgramListResponse } from 'src/types/requests/TrainingPrograms';
import { getValidationErrors } from 'src/types/validators/ajv';
import { validateResponseErrorMessage } from 'src/types/validators/ResponseErrorMessage';
import { validateResponseErrorsList } from 'src/types/validators/ResponseErrorsList';
import {
  validateTrainingProgramListResponse,
} from 'src/types/validators/TrainingProgramListResponse';

export async function getTrainingProgramList(
  accessToken: string,
  query?: { [key: string]: string | number },
): Promise<TrainingProgramListResponse | undefined> {
  const route = generateApiUrl(TP_PATH_PATTERNS.ROOT, undefined, query);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method: 'GET',
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (process.env.NODE_ENV !== 'development') {
    if (response.status !== 200) {
      console.error(`Groups get request failed with status ${response.status}`, json);
    }

    return json as TrainingProgramListResponse;
  }

  if (response.status !== 200) {
    if (validateResponseErrorsList(json)) {
      console.error(`Program list get request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Program list get request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Program list get request failed with status ${response.status}`, json);
    }
    return undefined;
  }

  if (!validateTrainingProgramListResponse(json)) {
    console.error('Program list get response structure is invalid. Probably API has changed', getValidationErrors(), json);
  }

  return json;
}
