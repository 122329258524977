import { createSlice } from '@reduxjs/toolkit';
import { Status } from 'src/types/Status';

import { fetchFacilitatorProgramList, fetchFacilitatorProgramListCaseHandlers } from './thunks';
import { FacilitatorProgramListState } from './types';

const initialState: FacilitatorProgramListState = {
  status: Status.IDLE,
  trainingPrograms: [],
};

const facilitatorProgramListSlice = createSlice({
  name: 'facilitatorProgramList',
  initialState,
  reducers: {
    clearFacilitatorProgramList: (state) => {
      state.trainingPrograms = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchFacilitatorProgramList
      .addCase(fetchFacilitatorProgramList.pending, fetchFacilitatorProgramListCaseHandlers.handlePending)
      .addCase(fetchFacilitatorProgramList.fulfilled, fetchFacilitatorProgramListCaseHandlers.handleFulfilled)
      .addCase(fetchFacilitatorProgramList.rejected, fetchFacilitatorProgramListCaseHandlers.handleRejected);
  },
});

export const { clearFacilitatorProgramList } = facilitatorProgramListSlice.actions;

export default facilitatorProgramListSlice.reducer;
