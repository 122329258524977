import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Status } from 'src/types/Status';
import { FlowReturn } from 'src/types/utils';
import { GroupMemberGetResponse } from 'src/types/validators/GroupMemberGetResponse';
import { GroupMemberRequest } from 'src/types/validators/GroupMemberRequest';

import { getGroupMemberships, postMembersToGroup, deleteMemberFromGroup } from './api';
import {
  GroupMemberListCaseHandlers, GroupMemberListQueryParams,
  AddGroupMemberListCaseHandlers,
  RemoveMemberFromGroupCaseHandlers,
  GroupMemberIdModel,
} from './types';

interface FetchGroupMembersParams {
  accessToken: string,
  groupId: string,
  size: number,
}

export const fetchGroupMembers = createAsyncThunk(
  'groups/fetchGroupMembers',
  async ({
    accessToken,
    groupId,
    size,
  }: FetchGroupMembersParams, { rejectWithValue }) => {
    if (!accessToken) {
      return rejectWithValue('User is not logged in');
    }

    const queryParams: GroupMemberListQueryParams = {
      size,
    };

    try {
      const response = await getGroupMemberships(accessToken, groupId, queryParams);
      if (!response) {
        return rejectWithValue('Failed to fetch group members');
      }

      return response;
    } catch (err: any) {
      console.error(err);

      return rejectWithValue(err.message);
    }
  },
);

export const fetchGroupMembersCaseHandlers: GroupMemberListCaseHandlers = {
  handlePending: (state) => {
    state.status = Status.LOADING;
  },
  handleFulfilled: (state, { payload }: PayloadAction<GroupMemberGetResponse>) => {
    state.items = payload.items;
    state.status = Status.SUCCEEDED;
  },
  handleRejected: (state, { payload }) => {
    state.status = Status.FAILED;
    console.error(payload);
  },
};

interface AddGroupMemberListParams {
  accessToken: string,
  groupId: string,
  groupMembers: GroupMemberIdModel[],
}

export const addGroupMemberList = createAsyncThunk(
  'groups/addGroupMemberList',
  async ({
    accessToken,
    groupId,
    groupMembers,
  }: AddGroupMemberListParams, { rejectWithValue }) => {
    if (!accessToken) {
      return rejectWithValue('User is not logged in');
    }

    try {
      type Response = FlowReturn<typeof postMembersToGroup>;
      const response: Response = await postMembersToGroup(accessToken, groupId, groupMembers as GroupMemberRequest);
      if (!response) {
        return rejectWithValue('Group members not updated');
      }

      return response;
    } catch (err: any) {
      console.error(err);

      return rejectWithValue(err.message);
    }
  },
);

export const addGroupMemberListCaseHandlers: AddGroupMemberListCaseHandlers = {
  handlePending: (state) => {
    state.status = Status.LOADING;
  },
  handleFulfilled: (state) => {
    state.status = Status.SUCCEEDED;
  },
  handleRejected: (state, { payload }) => {
    state.status = Status.FAILED;
    console.error(payload);
  },
};

interface RemoveMemberFromGroupParams {
  accessToken: string,
  groupId: string,
  userId: string
}

export const removeMemberFromGroup = createAsyncThunk(
  'groups/removeMemberFromGroup',
  async ({
    accessToken,
    groupId,
    userId,
  }: RemoveMemberFromGroupParams, { rejectWithValue }) => {
    if (!accessToken) {
      return rejectWithValue('User is not logged in');
    }

    try {
      type Response = FlowReturn<typeof deleteMemberFromGroup>;
      const response: Response = await deleteMemberFromGroup(accessToken, groupId, userId);
      if (!response) {
        return rejectWithValue('Group member not deleted');
      }

      return response;
    } catch (err: any) {
      console.error(err);

      return rejectWithValue(err.message);
    }
  },
);

export const removeMemberFromGroupCaseHandlers: RemoveMemberFromGroupCaseHandlers = {
  handlePending: (state) => {
    state.status = Status.LOADING;
  },
  handleFulfilled: (state) => {
    state.status = Status.SUCCEEDED;
  },
  handleRejected: (state, { payload }) => {
    state.status = Status.FAILED;
    console.error(payload);
  },
};
