import { EVENTS_PATH_PATTERNS, generateApiUrl, ObjectOfStrings } from 'src/constants/api';
import { getValidationErrors } from 'src/types/validators/ajv';
import { EventListResponse, validateEventListResponse } from 'src/types/validators/EventListResponse';
import { EventRequest } from 'src/types/validators/EventRequest';
import { EventResponse, validateEventResponse } from 'src/types/validators/EventResponse';
import { validateResponseErrorMessage } from 'src/types/validators/ResponseErrorMessage';
import { validateResponseErrorsList } from 'src/types/validators/ResponseErrorsList';

// Get events
export async function getEvents(
  accessToken: string,
  queryParams: ObjectOfStrings,
): Promise<EventListResponse | undefined> {
  const route = generateApiUrl(EVENTS_PATH_PATTERNS.ROOT, undefined, queryParams);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method: 'GET',
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (process.env.NODE_ENV !== 'development') {
    if (response.status !== 200) {
      console.error(`Events get request failed with status ${response.status}`, json);
    }

    return json as EventListResponse;
  }

  if (response.status !== 200) {
    if (validateResponseErrorsList(json)) {
      console.error(`Events get request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Events get request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Events get request failed with status ${response.status}`, json);
    }

    return undefined;
  }

  if (validateEventListResponse(json)) {
    return json as EventListResponse;
  }

  console.error('Events get response structure is invalid. Probably API has changed', getValidationErrors(), json);

  return undefined;
}

// Get event
export async function getEvent(
  accessToken: string,
  eventId: string,
): Promise<EventResponse | undefined> {
  const route = generateApiUrl(EVENTS_PATH_PATTERNS.EVENT, { eventId });

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method: 'GET',
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (process.env.NODE_ENV !== 'development') {
    if (response.status !== 200) {
      console.error(`Event get request failed with status ${response.status}`, json);
    }

    return json as EventResponse;
  }

  if (response.status !== 200) {
    if (validateResponseErrorsList(json)) {
      console.error(`Event get request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Event get request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Event get request failed with status ${response.status}`, json);
    }

    return undefined;
  }

  if (validateEventResponse(json)) {
    return json as EventResponse;
  }

  console.error('Event get response structure is invalid. Probably API has changed', getValidationErrors(), json);

  return undefined;
}

// Post event
export async function postEvent(
  accessToken: string,
  eventData: EventRequest,
): Promise<EventResponse | undefined> {
  const route = generateApiUrl(EVENTS_PATH_PATTERNS.ROOT);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const options = {
    method: 'POST',
    body: JSON.stringify(eventData),
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (process.env.NODE_ENV !== 'development') {
    if (response.status !== 201) {
      console.error(`Event post request failed with status ${response.status}`, json);
    }

    return json as EventResponse;
  }

  if (response.status !== 201) {
    if (validateResponseErrorsList(json)) {
      console.error(`Event post request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Event post request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Event post request failed with status ${response.status}`, json);
    }

    return undefined;
  }

  if (validateEventResponse(json)) {
    return json as EventResponse;
  }

  console.error('Event post response structure is invalid. Probably API has changed', getValidationErrors(), json);

  return undefined;
}

// Patch event
export async function patchEvent(
  accessToken: string,
  eventData: EventRequest,
): Promise<EventResponse | undefined> {
  const route = generateApiUrl(EVENTS_PATH_PATTERNS.ROOT);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const options = {
    method: 'PATCH',
    body: JSON.stringify(eventData),
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (process.env.NODE_ENV !== 'development') {
    if (response.status !== 200) {
      console.error(`Event patch request failed with status ${response.status}`, json);
    }

    return json as EventResponse;
  }

  if (response.status !== 200) {
    if (validateResponseErrorsList(json)) {
      console.error(`Event patch request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Event patch request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Event patch request failed with status ${response.status}`, json);
    }

    return undefined;
  }

  if (validateEventResponse(json)) {
    return json as EventResponse;
  }

  console.error('Event patch response structure is invalid. Probably API has changed', getValidationErrors(), json);

  return undefined;
}

// Delete event
export async function deleteEvent(
  accessToken: string,
  eventId: string,
): Promise<Boolean> {
  const route = generateApiUrl(EVENTS_PATH_PATTERNS.EVENT, { eventId });

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method: 'DELETE',
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (response.status !== 204) {
    if (validateResponseErrorsList(json)) {
      console.error(`Event delete request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Event delete request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Event delete request failed with status ${response.status}`, json);
    }

    return false;
  }

  return true;
}
