import { createSlice } from '@reduxjs/toolkit';
import { Status } from 'src/types/Status';

import {
  createGroup, createGroupCaseHandlers,
  deleteGroup, deleteGroupCaseHandlers,
  fetchGroup, fetchGroupCaseHandlers,
  fetchGroups, fetchGroupsCaseHandlers,
} from './thunks';
import { GroupListState, GroupState } from './types';

const groupListState: GroupListState = {
  status: Status.IDLE,
  groups: [],
};

const groupListSlice = createSlice({
  name: 'groupList',
  initialState: groupListState,
  reducers: {
    clearGroups: (state) => {
      state.groups = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch groups
      .addCase(fetchGroups.pending, fetchGroupsCaseHandlers.handlePending)
      .addCase(fetchGroups.fulfilled, fetchGroupsCaseHandlers.handleFulfilled)
      .addCase(fetchGroups.rejected, fetchGroupsCaseHandlers.handleRejected);
  },
});

export const { clearGroups } = groupListSlice.actions;

const groupFetchState: GroupState = {
  status: Status.IDLE,
};

const groupFetchSlice = createSlice({
  name: 'groupFetch',
  initialState: groupFetchState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetch group
      .addCase(fetchGroup.pending, fetchGroupCaseHandlers.handlePending)
      .addCase(fetchGroup.fulfilled, fetchGroupCaseHandlers.handleFulfilled)
      .addCase(fetchGroup.rejected, fetchGroupCaseHandlers.handleRejected);
  },
});

const groupCreateState: GroupState = {
  status: Status.IDLE,
};

const groupCreateSlice = createSlice({
  name: 'groupCreate',
  initialState: groupCreateState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // create group
      .addCase(createGroup.pending, createGroupCaseHandlers.handlePending)
      .addCase(createGroup.fulfilled, createGroupCaseHandlers.handleFulfilled)
      .addCase(createGroup.rejected, createGroupCaseHandlers.handleRejected);
  },
});

const groupDeleteState: GroupState = {
  status: Status.IDLE,
};

const groupDeleteSlice = createSlice({
  name: 'groupDelete',
  initialState: groupDeleteState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // delete group
      .addCase(deleteGroup.pending, deleteGroupCaseHandlers.handlePending)
      .addCase(deleteGroup.fulfilled, deleteGroupCaseHandlers.handleFulfilled)
      .addCase(deleteGroup.rejected, deleteGroupCaseHandlers.handleRejected);
  },
});

const groupCreateSliceReducer = groupCreateSlice.reducer;
const groupDeleteSliceReducer = groupDeleteSlice.reducer;
const groupFetchSliceReducer = groupFetchSlice.reducer;
const groupListSliceReducer = groupListSlice.reducer;
export {
  groupCreateSliceReducer, groupDeleteSliceReducer, groupFetchSliceReducer, groupListSliceReducer,
};
