import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { KEY_USER_MAPPING_ID } from 'src/constants/localStorage';
import { decodeToken } from 'src/helpers/jwt';
import { RootState } from 'src/redux/store';
import MeasurementsClient from 'src/services/measurements/MeasurementsClient';
import {
  getUserInfo,
  getOrganizationRoles,
  getUserMappings,
  searchUsersFromApi,
} from 'src/services/requests/user';
import { ThunkCaseHandlers } from 'src/types/Redux';
import { ParsedPlatformData, UserMappingsResponse } from 'src/types/requests/User';
import { UserRoles } from 'src/types/roles';
import { Status } from 'src/types/Status';
import { FlowReturn } from 'src/types/utils';
import { Role } from 'src/types/validators/OrganizationRolesResponse';
import { UserSearchResponse } from 'src/types/validators/UserSearchResponse';

import type { SearchUsersCaseHandlers, UserState } from './types';

type UserInfo = FlowReturn<typeof getUserInfo>;
type OrganizationRoles = FlowReturn<typeof getOrganizationRoles>;

export const fetchUserMappings = createAsyncThunk(
  'user/fetchUserMappings',
  async (userId: string, { getState, rejectWithValue }) => {
    const { auth } = getState() as RootState;
    const accessToken = auth.tokens?.accessToken;

    if (!accessToken) return rejectWithValue('fetchUserMappings: Missing access token.');

    type Response = FlowReturn<typeof getUserMappings>;
    const response: Response = await getUserMappings(userId, accessToken);

    if (!response || !response.items) return rejectWithValue('fetchUserMappings: The user mappings response was not received.');

    return response;
  },
);

export const fetchUserMappingsCaseHandlers: ThunkCaseHandlers<UserState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
  },
  handleFulfilled: (state, { payload }: PayloadAction<UserMappingsResponse>) => {
    state.status = Status.SUCCEEDED;
    state.userMappings = payload.items;

    if (!state.userMappingId) {
      const userMappingId = localStorage.getItem(KEY_USER_MAPPING_ID);

      if (!userMappingId) {
        console.warn('Unable to initialize Pendo: user mapping id not found.');
        return;
      }

      state.userMappingId = parseInt(userMappingId, 10);
    }

    const userMapping = payload.items.find((mapping) => mapping.id === state.userMappingId);
    if (!userMapping) {
      console.warn('Unable to initialize Pendo: no user mapping with required id found.');
      return;
    }

    // Start Pendo if platformUserId and platformCustomer fields are present
    // environment variable for Pendo key is required
    if (userMapping.platformUserId && userMapping.platformCustomer) {
      MeasurementsClient.init(userMapping.platformUserId, userMapping.platformCustomer);
    } else {
      console.warn('Unable to initialize Pendo: required platform data not found.');
    }

    // Set platformData
    if (userMapping.platformData) {
      const platformData: ParsedPlatformData = JSON.parse(userMapping.platformData);

      if (platformData) {
        state.platformData = platformData;
      }
    }
  },
  handleRejected: (state, action) => {
    state.status = Status.FAILED;
    console.error(action.payload);
  },
};

export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async (_, { rejectWithValue, getState, dispatch }) => {
    const { auth } = getState() as RootState;
    if (!auth.tokens) return rejectWithValue('Missing tokens.');

    const { accessToken } = auth.tokens;

    if (!accessToken) return rejectWithValue('Not found access token.');

    const userId = decodeToken(accessToken).user_id;

    if (!userId) return rejectWithValue('fetchUser: No userId in decoded token');

    const response: UserInfo = await getUserInfo(userId, accessToken);

    if (!response) {
      return rejectWithValue('The UserInfo response is not received.');
    }

    dispatch(fetchUserMappings(userId));

    const organizationRoles: OrganizationRoles = await getOrganizationRoles(userId, accessToken);

    if (!organizationRoles || !organizationRoles.items || !organizationRoles.items.length) {
      return rejectWithValue('The organization roles are not retrieved.');
    }

    // Retrieve available roles
    const rolesArr = organizationRoles.items.reduce(
      (acc: Role[], organizationRole) => {
        acc.push(organizationRole.role);
        return acc;
      },
      [],
    );

    let roles = rolesArr.map((role) => {
      switch (role) {
        case Role.LEARNER:
          return UserRoles.LEARNER;
        case Role.ORG_ADMIN:
          return UserRoles.ADMIN;
        case Role.INSTRUCTIONAL_DESIGNER:
          return UserRoles.ADMIN;
        case Role.FACILITATOR:
          return UserRoles.FACILITATOR;
        default:
          return UserRoles.LEARNER;
      }
    });
    // Remove duplicated roles
    roles = [...new Set(roles)];

    const organizationIds = organizationRoles.items.map((role) => role.organizationId) as number[];

    return {
      user: response,
      roles,
      organizationIds,
    };
  },
);

export const fetchUserCaseHandlers: ThunkCaseHandlers<UserState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
  },
  handleFulfilled: (
    state,
    { payload }: PayloadAction<{
      user: UserInfo,
      roles: string[],
      organizationIds: number[]
    }>,
  ) => {
    const {
      user,
      roles,
      organizationIds,
    } = payload;

    state.user = {
      id: user?.id?.toString(),
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.username,
      avatarUrl: user?.avatarUrl || undefined,
      roles: roles || [],
      activeRole: roles?.length ? roles[0] : '',
      language: user?.language,
    };

    state.organizationIds = organizationIds;
    state.status = Status.SUCCEEDED;
  },
  handleRejected: (state, action) => {
    state.status = Status.FAILED;
    console.error(action.payload);
  },
};

interface SearchUsersParams {
  accessToken: string;
  username: string;
  signal?: AbortSignal;
}

export const searchUsers = createAsyncThunk(
  'user/search',
  async ({
    accessToken,
    username,
    signal,
  }: SearchUsersParams, { rejectWithValue }) => {
    if (!accessToken) {
      return rejectWithValue('Users search: User is not logged in');
    }

    type Response = FlowReturn<typeof searchUsersFromApi>;
    const response: Response = await searchUsersFromApi(accessToken, { username }, signal);

    if (!response || !Array.isArray(response.items)) {
      return rejectWithValue('Users search: Failed trying to search for users');
    }

    return response;
  },
);

export const searchUsersCaseHandlers: SearchUsersCaseHandlers = {
  handlePending: (state) => {
    state.status = Status.LOADING;
  },
  handleFulfilled: (state, { payload }: PayloadAction<UserSearchResponse>) => {
    state.userResults = payload.items;
    state.status = Status.SUCCEEDED;
  },
  handleRejected: (state, action) => {
    state.status = Status.FAILED;
    console.error(action.payload);
  },
};
