import { createSlice } from '@reduxjs/toolkit';
import { Status } from 'src/types/Status';

import {
  fetchGroupMembers, addGroupMemberList, removeMemberFromGroup,
  fetchGroupMembersCaseHandlers, addGroupMemberListCaseHandlers, removeMemberFromGroupCaseHandlers,
} from './thunks';
import { GroupMemberListState, AddGroupMemberListState, RemoveMemberFromGroupState } from './types';

const groupMemberListState: GroupMemberListState = {
  status: Status.IDLE,
  items: [],
};

const groupMemberListSlice = createSlice({
  name: 'groupMemberList',
  initialState: groupMemberListState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetch groups
      .addCase(fetchGroupMembers.pending, fetchGroupMembersCaseHandlers.handlePending)
      .addCase(fetchGroupMembers.fulfilled, fetchGroupMembersCaseHandlers.handleFulfilled)
      .addCase(fetchGroupMembers.rejected, fetchGroupMembersCaseHandlers.handleRejected);
  },
});

const groupMembersAddState: AddGroupMemberListState = {
  status: Status.IDLE,
  items: [],
  count: 0,
};

const groupMemberAddSlice = createSlice({
  name: 'groupMembersAdd',
  initialState: groupMembersAddState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetch group
      .addCase(addGroupMemberList.pending, addGroupMemberListCaseHandlers.handlePending)
      .addCase(addGroupMemberList.fulfilled, addGroupMemberListCaseHandlers.handleFulfilled)
      .addCase(addGroupMemberList.rejected, addGroupMemberListCaseHandlers.handleRejected);
  },
});

const groupMemberRemoveState: RemoveMemberFromGroupState = {
  status: Status.IDLE,
  result: false,
};

const groupMemberRemoveSlice = createSlice({
  name: 'groupMemberRemove',
  initialState: groupMemberRemoveState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // create group
      .addCase(removeMemberFromGroup.pending, removeMemberFromGroupCaseHandlers.handlePending)
      .addCase(removeMemberFromGroup.fulfilled, removeMemberFromGroupCaseHandlers.handleFulfilled)
      .addCase(removeMemberFromGroup.rejected, removeMemberFromGroupCaseHandlers.handleRejected);
  },
});

const groupMemberListSliceReducer = groupMemberListSlice.reducer;
const groupMemberAddSliceReducer = groupMemberAddSlice.reducer;
const groupMemberRemoveSliceReducer = groupMemberRemoveSlice.reducer;
export {
  groupMemberListSliceReducer, groupMemberAddSliceReducer, groupMemberRemoveSliceReducer,
};
