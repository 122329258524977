import * as React from 'react';

import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography,
} from '@mui/material';

import './AlertDialog.scss';

interface Props {
  title?: string;
  description?: string;
  cancelButtonText: string;
  onCancelPress?: () => void;
  confirmButtonText?: string;
  onConfirmPress?: () => void;
  children?: React.ReactNode;
  disabled?: boolean
}

export default function AlertDialog({
  title, description, cancelButtonText, onCancelPress, confirmButtonText, onConfirmPress, children, disabled,
}: Props) {
  const handleConfirmPress = () => {
    if (onConfirmPress) {
      onConfirmPress();
    }
  };

  const handleCancelPress = () => {
    if (onCancelPress) {
      onCancelPress();
    }
  };

  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      open
      aria-labelledby="AlertDialog-title"
      aria-describedby="AlertDialog-description"
    >
      {
          !!title && (
          <DialogTitle id="AlertDialog-title">
            {title}
          </DialogTitle>
          )
        }

      <DialogContent>
        {description && (
          <Typography className="AlertDialog-text">
            {description}
          </Typography>
        )}

        {children}
      </DialogContent>

      <DialogActions>
        <Button
          className="AlertDialog-button"
          disabled={disabled}
          onClick={handleCancelPress}
        >
          {cancelButtonText}
        </Button>

        {
            !!confirmButtonText && (
            <Button
              className="AlertDialog-button"
              disabled={disabled}
              onClick={handleConfirmPress}
              autoFocus
            >
              {confirmButtonText}
            </Button>
            )
          }
      </DialogActions>
    </Dialog>
  );
}
