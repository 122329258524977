import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KEY_USER_MAPPING_ID } from 'src/constants/localStorage';
import { ParsedPlatformData } from 'src/types/requests/User';
import { UserRoles } from 'src/types/roles';
import { Status } from 'src/types/Status';

import {
  fetchUser,
  fetchUserCaseHandlers,
  fetchUserMappings,
  fetchUserMappingsCaseHandlers,
  searchUsers,
  searchUsersCaseHandlers,
} from './thunks';
import { PlatformRoles, UserSearchState, UserState } from './types';

export const userState: UserState = {
  status: Status.IDLE,
  organizationIds: [],
  user: undefined,
  userMappings: undefined,
  userMappingId: undefined,
  platformData: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState: userState,
  reducers: {
    switchActiveRole: (state, { payload }: PayloadAction<string>) => {
      const roleName = payload;

      if (!state.user) return;

      const foundRole = state.user.roles.find((role) => role === roleName);

      if (foundRole) {
        state.user.activeRole = foundRole;
      } else {
        console.error('User is not allowed to desired role');
      }

      // switch user mapping
      const userMapping = state.userMappings?.find((mapping) => {
        const platformRole = roleName === UserRoles.LEARNER ? PlatformRoles.LEARNER : PlatformRoles.ADMIN;
        return mapping.platformRole === platformRole;
      });

      if (!userMapping) return;

      state.userMappingId = userMapping.id;
      localStorage.setItem(KEY_USER_MAPPING_ID, String(userMapping.id));

      if (userMapping.platformData) {
        const platformData: ParsedPlatformData = JSON.parse(userMapping.platformData);

        if (platformData) {
          state.platformData = platformData;
        }
      }
    },
    clearUser: (state) => {
      state.user = undefined;
    },
    setUserMappingId: (state, { payload }: PayloadAction<string | undefined>) => {
      if (payload) {
        state.userMappingId = parseInt(payload, 10);
        localStorage.setItem(KEY_USER_MAPPING_ID, payload); // set it to localStorage to persist Pendo across page refreshes
      } else {
        state.userMappingId = undefined;
        localStorage.removeItem(KEY_USER_MAPPING_ID);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchUserMappings
      .addCase(fetchUserMappings.pending, fetchUserMappingsCaseHandlers.handlePending)
      .addCase(fetchUserMappings.fulfilled, fetchUserMappingsCaseHandlers.handleFulfilled)
      .addCase(fetchUserMappings.rejected, fetchUserMappingsCaseHandlers.handleRejected)

      // fetchUser
      .addCase(fetchUser.pending, fetchUserCaseHandlers.handlePending)
      .addCase(fetchUser.fulfilled, fetchUserCaseHandlers.handleFulfilled)
      .addCase(fetchUser.rejected, fetchUserCaseHandlers.handleRejected);
  },
});

export const userSearchState: UserSearchState = {
  status: Status.IDLE,
  userResults: [],
};

const userSearchSlice = createSlice({
  name: 'userSearch',
  initialState: userSearchState,
  reducers: {
    clearUserResults(state) {
      state.userResults = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // searchUsers
      .addCase(searchUsers.pending, searchUsersCaseHandlers.handlePending)
      .addCase(searchUsers.fulfilled, searchUsersCaseHandlers.handleFulfilled)
      .addCase(searchUsers.rejected, searchUsersCaseHandlers.handleRejected);
  },
});

const userReducer = userSlice.reducer;
const userSearchReducer = userSearchSlice.reducer;

export { userReducer, userSearchReducer };

export const {
  switchActiveRole,
  clearUser,
  setUserMappingId,
} = userSlice.actions;

export const { clearUserResults } = userSearchSlice.actions;
